<template>
  <b-container id="saml-configuration-view" class="mt-4" fluid>
    <b-row>
      <b-col>
        <h3>SAML Configuration</h3>
        <router-link :to="{ name: 'SamlCoreConfigurationList' }" class="d-flex justify-content-left align-items-center">
          <b-icon icon="arrow-left" aria-label="Back to configuration list"/>
          Back to List
        </router-link>
      </b-col>
    </b-row>
    <div v-if="loading" class="row mt-4">
      <div class="col-12 d-flex justify-content-center">
        <div class="spinner spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <template v-else>
      <b-row class="mt-4">
        <b-col>
          <b-form-group
            label="UUID"
            description="Unique configuration identifier"
            label-for="configuration-uuid">
            <b-form-input
              id="configuration-uuid" type="text" placeholder="" :disabled="true"
              :value="data.uuid"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Name"
            description="Configuration name"
            label-for="configuration-name">
            <b-form-input
              id="configuration-name" type="text" placeholder="" :disabled="true"
              :value="data.name"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Certificate"
            description="Key Pair Public Certificate"
            label-for="configuration-public-certificate">
            <b-form-textarea
              id="configuration-public-certificate" placeholder="" :disabled="true"
              rows="10"
              :value="data.public_key"/>
          </b-form-group>

          <b-button variant="light" size="sm" class="mb-2" @click="copyToClipboard(data.public_key)">
            <b-icon icon="clipboard" aria-label="Copy to clipboard"/>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import Vue from 'vue';
import { get } from '@/api/saml/core/configurations';

export default {
  name: 'SamlCoreConfigurationView',
  beforeMount() {
    this.loading = true;
    get(this.$route.params.uuid)
      .then(data => {
        this.data = data;
      })
      .catch(err => {
        Vue.prototype.$noty.error(`Failed to get configuration: ${err}`);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  methods: {
    copyToClipboard(value) {
      if (!navigator || !navigator.clipboard) {
        this.$noty.error('Browser feature unsupported.');
        return;
      }

      navigator.clipboard
        .writeText(value)
        .then(() => this.$noty.success('Copied successfully.'))
        .catch(e => this.$noty.error(e.message || 'Unknown error.'));
    },
  },
};
</script>
